<template>
    <div>
        <main-menu>

        </main-menu>


        <div class="columns is-centered " style="margin-top: 10px">
            <div style="margin-top: 10px" class="column is-two-thirds container-box">
                <div class="columns" style="margin-bottom: 0px;">
                    <div class="column">
                        <div class="columns header">
                            <div data-v-3ad9bea0="" class="column"><p data-v-3ad9bea0="" class="day-title">Landing
                                Pages</p>
                            </div>
                        </div>
                    </div>
                </div>

                <section>
                    <div class="columns" style="margin-top: 10px">
                        <div class="column is-one-third">
                            <inertia-link class="button is-primary" href="/landings/create">Create</inertia-link>
                        </div>
                    </div>
                    <b-table
                        :data="data"
                        :loading="loading"
                        paginated
                        detailed
                        backend-pagination
                        :has-detailed-visible="(row, index) => row.custom && row.custom.length > 2"
                        :total="total"
                        :per-page="perPage"
                        @page-change="onPageChange"
                        aria-next-label="Next page"
                        aria-previous-label="Previous page"
                        aria-page-label="Page"
                        aria-current-label="Current page"
                        backend-sorting
                        :default-sort-direction="defaultSortOrder"
                        :default-sort="[sortField, sortOrder]"
                        @sort="onSort"

                    >

                        <template slot-scope="props">

                            <b-table-column field="id" label="ID" sortable>
                                {{ props.row.id }}
                            </b-table-column>

                            <b-table-column field="name" label="Name" sortable>
                                {{ props.row.name }}
                            </b-table-column>
                            <b-table-column field="url" label="URL" sortable>

                                <b-button tag="a"
                                          type="is-primary"
                                          :href="props.row.portal_url"
                                          target="_blank">
                                    Open landing page
                                </b-button>
                            </b-table-column>

                            <b-table-column field="" label="" centered>
                                <b-dropdown aria-role="list">
                                    <i class="fas fa-ellipsis-v table-dropdown-trigger" slot="trigger"
                                       style="cursor: pointer"></i>
                                    <inertia-link :href="getEditUrl(props.row.id)">
                                        <b-dropdown-item aria-role="listitem"><span class="no-style">Edit</span>
                                        </b-dropdown-item>
                                    </inertia-link>
                                    <b-dropdown-item aria-role="listitem"><span
                                        v-on:click="deleteLandingPage(props.row.id)"
                                        type="submit">Delete</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-table-column>
                        </template>

                    </b-table>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import MainMenu from "../../Shared/MainMenu";

export default {
    components: {
        MainMenu
    },
    data() {
        return {
            searchModel: '',
            searchQuery: '',
            data: [],
            total: 0,
            loading: false,
            sortField: 'id',
            sortBy: 'id',
            sortOrder: 'desc',
            defaultSortOrder: 'desc',
            page: 1,
            perPage: 5
        }
    },
    methods: {
        getEditUrl(id) {
            return this.route('landings.edit', {landing: id})
        },
        onSort(field, order) {
            this.sortField = field,
                this.sortOrder = order,
                this.loadAsyncData()
        },
        onPageChange(page) {
            this.page = page
            this.loadAsyncData()
        },
        loadAsyncData() {
            const params = [
                `sort_by=${this.sortBy}`,
                `sort_order=${this.sortOrder}`,
                `page=${this.page}`,
                `search_query=${this.searchQuery}`
            ]

            let paramsParsed = params.join('&')
            this.loading = true;

            axios.get(`/landings/list?${paramsParsed}`)
                .then(({data}) => {
                    console.log(data);
                    this.data = [];
                    let currentTotal = data.total;
                    if (data.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000;
                    }
                    this.total = currentTotal;
                    data.data.forEach((item) => {
                        this.data.push(item);
                    });


                    this.loading = false;
                })
                .catch((error) => {
                    this.data = [];
                    this.total = 0;
                    this.loading = false;
                    throw error;
                })
        },
        deleteLandingPage(id) {
            this.$inertia.delete(this.route('landings.destroy', {landing: id}));
        },
        getShowPage(id) {
            return this.route('landing.showPublic', {landing: id});
        }
    },
    mounted() {
        this.loadAsyncData();
    },

    name: "Index"
}

</script>

<style scoped>
</style>
