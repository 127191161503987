<template>
    <div>
        <main-menu></main-menu>

    </div>
</template>

<script>
    import MainMenu from "../../Shared/MainMenu";

    export default {
        components: {
            MainMenu
        },
        name: "Index"
    }
</script>

<style scoped>

</style>
