
/**
 * First, we will load all of this project's Javascript utilities and other
 * dependencies. Then, we will be ready to develop a robust and powerful
 * application frontend using useful Laravel and JavaScript libraries.
 */

import Buefy from 'buefy'
import Vue from 'vue'
import {InertiaApp} from '@inertiajs/inertia-vue'

require('./bootstrap');

window.eventBus = new Vue({});

Vue.use(Buefy);


Vue.config.productionTip = false
Vue.use(InertiaApp)
let app = document.getElementById('app')
Vue.mixin({ methods: { route: window.route } });

new Vue({
    render: h => h(InertiaApp, {
        props: {
            initialPage: JSON.parse(app.dataset.page),
            resolveComponent: name => require(`./Pages/${name}`).default,
        },
    }),
}).$mount(app)

