<template>
    <div>
        <main-menu></main-menu>
        <div class="columns is-centered">
            <div class="column is-two-thirds">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <inertia-link href="/users">Users</inertia-link>
                        </li>
                        <li>
                            <inertia-link class="active-breadcrumb" :href="getEditUrl()">Update
                            </inertia-link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>


        <div class="column">
            <div class="columns is-centered is-vcentered">
                <div style="margin-top: 10px" class="column is-two-thirds">
                    <div class="container-box">
                        <div class="card-content">
                            <div style="color: green" v-if="$page.flash.success"> {{$page.flash.success}}</div>
                            <form method="POST" @submit.prevent="submit()">
                                <div class="field">
                                    <b-field
                                        label="Name"
                                        :type="getType('name')"
                                        :message="getText('name')">
                                        <b-input type="text" v-model="form.name">
                                        </b-input>
                                    </b-field>
                                </div>

                                <div class="field">
                                    <b-field
                                        label="Email"
                                        :type="getType('email')"
                                        :message="getText('email')">
                                        <b-input type="text" placeholder="email@email.com"
                                                 v-model="form.email">
                                        </b-input>
                                    </b-field>
                                </div>



                                <div class="field is-grouped is-grouped-right">
                                    <div class="control">
                                        <b-button @click="submit" :loading="isLoading" class="button is-primary">
                                            Update
                                        </b-button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MainMenu from "@/Shared/MainMenu";

    export default {
        components: {
            MainMenu
        },
        data() {
            return {
                isLoading: false,
                form: {
                    id: null,
                    name: null,
                    email: null,
                }
            }
        },
        watch: {
            country: function () {

                this.updateCity()
            }
        },
        props: {
            userData: Object,
        },
        mounted() {
            this.form.name = this.userData.user.name;
            this.form.id = this.userData.user.id;
            this.form.email = this.userData.user.email;
        },
        methods: {
            getUpdateUrl() {

                return this.route('users.edit', {'user': this.userData.user.id});
            },
            getEditUrl() {
                return '/users/' + this.form.id + '/edit';
            },

            getType(property) {
                if (this.$page.errors[property]) {

                    return 'is-danger';
                }

                return '';
            },
            getText(property) {
                if (this.$page.errors[property]) {

                    let text = '';
                    for (let i = 0; i < this.$page.errors[property].length; i++) {
                        text = text + this.$page.errors[property][i] + ' ';
                    }
                    return text;

                }
                return '';
            },
            submit() {
                let that = this;

                that.isLoading = true;
                this.$inertia.put('/users/' + that.userData.user.id, this.form)
                    .then(function () {
                        that.isLoading = false;
                    });
            }
        },
        name: "Create"
    }
</script>

<style scoped>

</style>
