<template>
    <div>
        <main-menu></main-menu>

        <div class="columns is-centered">
            <div class="column is-two-thirds">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <inertia-link href="/users">Users</inertia-link>
                        </li>


                        <li>
                            <inertia-link class="active-breadcrumb" href="/users/create">
                                Create
                            </inertia-link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div class="column">
            <div class="columns is-centered is-vcentered">
                <div style="margin-top: 10px" class="column is-two-thirds">
                    <div class="container-box">
                        <div class="card-content">
                            <div style="color: green" v-if="$page.flash.success"> {{$page.flash.success}}</div>
                            <form method="POST" @submit.prevent="submit()">
                                <div class="field">
                                    <b-field
                                        label="Name"
                                        :type="getType('name')"
                                        :message="getText('name')">
                                        <b-input type="text" v-model="form.name">
                                        </b-input>
                                    </b-field>
                                </div>

                                <div class="field">
                                    <b-field
                                        label="Email"
                                        :type="getType('email')"
                                        :message="getText('email')">
                                        <b-input type="text" placeholder="email@email.com"
                                                 v-model="form.email">
                                        </b-input>
                                    </b-field>
                                </div>
                                <div class="field">
                                    <b-field
                                        label="Password"
                                        :type="getType('password')"
                                        :message="getText('password')">
                                        <b-input type="password" v-model="form.password">
                                        </b-input>
                                    </b-field>
                                </div>

                                <div class="field">
                                    <b-field
                                        label="Confirm Password"
                                        :type="getType('confirmPassword')"
                                        :message="getText('confirmPassword')">
                                        <b-input type="password" v-model="form.password_confirmation">
                                        </b-input>
                                    </b-field>
                                </div>

                                <div class="field is-grouped is-grouped-right">
                                    <div class="control">
                                        <b-button @click="submit()" :loading="isLoading" class="button is-primary">Submit</b-button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import MainMenu from "../../Shared/MainMenu";
    export default {
        components: {
            MainMenu
        },
        data() {
            return {
                isLoading: false,
                form: {
                    password: null,
                    password_confirmation: null,
                    name: null,
                    email: null,
                }
            }
        },

        props: {
            userData: Object,
        },
        methods: {
            getCreateUrl() {

                return "/users/create";
            },
            getType(property) {
                if (this.$page.errors[property]) {

                    return 'is-danger';
                }

                return '';
            },
            getText(property) {
                if (this.$page.errors[property]) {

                    let text = '';
                    for (let i = 0; i < this.$page.errors[property].length; i++) {
                        text = text + this.$page.errors[property][i] + ' ';
                    }
                    return text;

                }
                return '';
            },
            submit() {
                let that = this;

                console.log('submit form');
                that.isLoading = true;
                this.$inertia.post('/users', this.form)
                    .then(function () {
                        that.isLoading = false;
                    });
            }
        },
        name: "Create"
    }
</script>

<style scoped>

</style>
