<template>
<div>

    <main-menu></main-menu>
    <users-table style="margin-top: 10px">

    </users-table>
</div>
</template>

<script>
    import MainMenu from "../../Shared/MainMenu";
    import UsersTable from "../../Shared/UsersTable";

    export default {
        name: "Index",
        components: {
            MainMenu,
            UsersTable
        },
    }
</script>

<style scoped>

</style>
