<template>
    <div>

        <main-menu>
        </main-menu>

        <div class="columns is-centered">
            <div class="column is-two-thirds">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <inertia-link href="/campaigns">Campaigns</inertia-link>
                        </li>
                        <li>
                            <inertia-link :href="this.route('affiliates.edit', {affiliate: this.payoutData.affiliate.id})">
                                Affiliates
                            </inertia-link>
                        </li>
                        <li>
                            <inertia-link class="active-breadcrumb"
                                          :href="this.route('payout.create', {affiliate: this.payoutData.affiliate.id})">
                                Create Payout
                            </inertia-link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="columns is-centered " style="margin-top: 10px">
            <div style="margin-top: 10px" class="column is-two-thirds container-box">
                <span style="color: #3CD160" v-if="this.$page.flash.success">
                    {{ this.$page.flash.success }}
                </span>
                <div class="columns" style="margin-bottom: 0px;">
                    <div class="column">
                        <div class="columns header">
                            <div data-v-3ad9bea0="" class="column"><p data-v-3ad9bea0="" class="day-title">Create
                                Payout</p></div>
                        </div>
                    </div>
                </div>
                <form @submit.prevent="submit" style="margin-top: 10px">


                    <div class="columns no-margin">


                        <div class="column">

                            <b-field
                                label="Currency">
                                <b-select placeholder="Choose currency" expanded v-model="form.currency">
                                    <option value="EUR">EUR</option>
                                    <option value="ARS">ARS</option>
                                    <option value="USD">USD</option>
                                </b-select>
                            </b-field>
                        </div>

                        <div class="column">
                            <b-field label="Amount"
                                     :type="getErrorClass('payout')"
                                     :message="getText('payout')">
                                <b-input type="number"
                                         v-model="form.payout">
                                </b-input>
                            </b-field>
                        </div>
                    </div>

                    <div class="columns no-margin">
                        <div class="column is-half">
                            <b-field
                                label="Type"
                                :type="getErrorClass('type_id')"
                                :message="getText('type_id')">
                                <b-select expanded v-model="form.type_id">
                                    <option :key="item.id" v-for="item in this.payoutData.types" :value="item.id">
                                        {{ item.description + ' (' + item.name + ')' }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>

                        <div class="column">
                            <b-field label="Payout Date"
                                     :type="getErrorClass('payout_date')"
                                     :message="getText('payout_date')">
                                <b-input type="date"
                                         maxlength="100"
                                         v-model="form.payout_date">
                                </b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="field is-grouped is-grouped-right">
                        <div class="control">
                            <button class="button is-primary">Submit</button>
                        </div>
                    </div>

                    <input type="hidden" v-model="form.affiliate_id">
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import MainMenu from "../../Shared/MainMenu";

export default {
    name: "Create",
    props: {payoutData: Object},
    components: {
        MainMenu,
    },
    data() {
        return {
            form: {
                affiliate_id: this.payoutData.affiliate.id,
                currency: null,
                payout: null,
                payout_date: null,
                type_id: 1,
            },
        }
    },
    methods: {
        getErrorClass(attribute) {
            if (this.$page.errors[attribute]) {

                return 'is-danger';
            }

            return '';
        },
        getText(attribute) {
            if (this.$page.errors[attribute]) {
                let text = '';
                for (let i = 0; i < this.$page.errors[attribute].length; i++) {
                    text = text + this.$page.errors[attribute][i] + ' ';
                }
                return text;

            }

            return '';
        },
        submit() {
            this.$inertia.post(this.route('payout.store'), this.form);
        },
    }
}
</script>

<style scoped>

</style>
