<template>
    <div>

        <main-menu>
        </main-menu>

        <div class="columns is-centered">
            <div class="column is-two-thirds">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <inertia-link href="/campaigns">Campaigns</inertia-link>
                        </li>

                        <li>
                            <inertia-link
                                :href="this.route('campaigns.edit', {campaign: this.campaignData.campaign.id})">
                                Affiliate Setup
                            </inertia-link>
                        </li>
                        <li>
                            <inertia-link class="active-breadcrumb"
                                          :href="this.route('setup.create', {campaign: this.campaignData.campaign.id})">
                                Create
                            </inertia-link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="columns is-centered " style="margin-top: 10px">
            <div style="margin-top: 10px" class="column is-two-thirds container-box">
                <span style="color: #3CD160" v-if="this.$page.flash.success">
                    {{ this.$page.flash.success }}
                </span>

                <div class="columns" style="margin-bottom: 0px;">
                    <div class="column">
                        <div class="columns header">
                            <div data-v-3ad9bea0="" class="column"><p data-v-3ad9bea0="" class="day-title">Create</p>
                            </div>
                        </div>
                    </div>
                </div>

                <form @submit.prevent="submit" style="margin-top: 10px">

                    <div class="columns no-margin">
                        <div class="column">
                            <b-field
                                label="Affiliate"
                                :type="getErrorClass('affiliate_id')"
                                :message="getText('affiliate_id')">
                                <b-select v-on:input="updateDependentsOfAffiliate()" placeholder="Select a affiliate"
                                          expanded v-model="form.affiliate_id">
                                    <option v-for="item in campaignData.affiliates" :value="item.id">
                                        {{ item.affiliate_name }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Tracking Code"
                                     :type="getErrorClass('tracking_code')"
                                     :message="getText('tracking_code')">
                                <b-input type="text" disabled readonly
                                         v-model="form.tracking_code">
                                </b-input>
                            </b-field>
                        </div>
                    </div>

                    <div class="columns no-margin">
                        <div class="column">
                            <b-field label="Pixel"
                                     :type="getErrorClass('pixel_id')"
                                     :message="getText('pixel_id')">
                                <b-select placeholder="Select a pixel" expanded
                                          v-model="form.pixel_id">
                                    <option v-for="item in pixel" :value="item.id">{{ item.url }}</option>
                                </b-select>

                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Accuracy"
                                     :type="getErrorClass('accuracy')"
                                     :message="getText('accuracy')">
                                <b-input type="number" aria-placeholder="From 0 to 100"
                                         v-model="form.accuracy">
                                </b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns no-margin">
                        <div class="column is-half">
                            <b-field label="Payout"
                                     :type="getErrorClass('payout_id')"
                                     :message="getText('payout_id')">
                                <b-select placeholder="Select a payout" expanded
                                          v-model="form.payout_id">
                                    <option v-for="item in payouts" :value="item.id">{{ getPayoutText(item) }}</option>
                                </b-select>
                            </b-field>
                        </div>

                        <div class="column">

                            <b-field label="Setup Name"
                                     :type="getErrorClass('name')"
                                     :message="getText('name')">
                                <b-input type="text"
                                         maxlength="100"
                                         v-model="form.name">
                                </b-input>
                            </b-field>
                        </div>
                    </div>

                    <div class="field is-grouped is-grouped-right">
                        <div class="control">
                            <button class="button is-primary">Submit</button>
                        </div>
                    </div>

                    <input type="hidden" v-model="this.form.campaign_id">
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import MainMenu from "../../Shared/MainMenu";

export default {
    name: "Create",
    props: {campaignData: Object},
    components: {
        MainMenu,
    },
    data() {
        return {
            pixel: [],
            payouts: [],
            form: {
                campaign_id: this.campaignData.campaign.id,
                accuracy: 100,
                affiliate_id: null,
                tracking_code: null,
                payout_id: null,
                pixel_id: null,
                name: null,
            },
        }
    },

    methods: {
        getPayoutText(payout) {
            return `${payout.payout} ${payout.currency}`;
        },
        updateDependentsOfAffiliate() {
            this.form.tracking_code = new Date().valueOf().toString(36) + Math.random().toString(36).substr(2)

            let that = this;
            axios.get(`/fetch/pixels/${this.form.affiliate_id}`).then(function (response) {
                that.pixel = response.data;
            });

            axios.get(`/fetch/payouts/${this.form.affiliate_id}`).then(function (response) {
                that.payouts = response.data;
            });
        },
        getErrorClass(attribute) {
            if (this.$page.errors[attribute]) {

                return 'is-danger';
            }

            return '';
        },
        getText(attribute) {
            if (this.$page.errors[attribute]) {
                let text = '';
                for (let i = 0; i < this.$page.errors[attribute].length; i++) {
                    text = text + this.$page.errors[attribute][i] + ' ';
                }
                return text;

            }

            return '';
        },
        submit() {
            this.$inertia.post(this.route('setup.store'), this.form);
        },
    }
}
</script>

<style scoped>

</style>
