<style scoped>

.no-margin {
    margin: 0;
}
</style>
<template>
    <div>
        <main-menu>
        </main-menu>

        <div class="columns is-centered">
            <div class="column is-two-thirds">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <inertia-link href="/campaigns">Campaigns</inertia-link>
                        </li>
                        <li>
                            <inertia-link class="active-breadcrumb"
                                          :href="this.route('campaigns.edit', {campaign: this.campaignData.campaign.id})">
                                Update
                            </inertia-link>
                        </li>
                    </ul>
                </nav>
            </div>

        </div>
        <div class="columns is-centered " style="margin-top: 10px">
            <div style="margin-top: 10px" class="column is-two-thirds container-box">
                <div class="columns" style="margin-bottom: 0px;">
                    <div class="column">
                        <div class="columns header">
                            <div data-v-3ad9bea0="" class="column"><p data-v-3ad9bea0="" class="day-title">Update
                                Campaign</p></div>
                        </div>
                    </div>
                </div>
                <span style="color: #3CD160" v-if="this.$page.flash.success">
                    {{ this.$page.flash.success }}
                </span>

                <form @submit.prevent="submit">

                    <div class="columns no-margin" style="margin-top: 5px">
                        <div class="column is-half">

                            <b-field label="Name"
                                     :type="getErrorClass('name')"
                                     :message="getText('name')">
                                <b-input type="text"
                                         maxlength="100"
                                         v-model="form.name">
                                </b-input>
                            </b-field>
                        </div>

                        <div class="column">
                            <b-field label="Type"
                                     :type="getErrorClass('type')"
                                     :message="getText('type')">
                                <b-select
                                    placeholder="type"
                                    v-model="form.type"
                                    expanded>
                                    <option v-for="item in this.campaignData.types" :value="item.id">{{ item.name }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>

                    </div>


                    <div class="columns no-margin">
                        <div class="column">
                            <b-field label="Start date"
                                     :type="getErrorClass('start_date')"
                                     :message="getText('start_date')">
                                <b-input type="date"
                                         maxlength="100"
                                         v-model="form.start_date">
                                </b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Subscription service"
                                     :type="getErrorClass('subscription_service_id')"
                                     :message="getText('subscription_service_id')">
                                <b-select
                                    placeholder="Small"
                                    v-model="form.subscription_service_id"
                                    expanded>
                                    <option v-for="item in this.campaignData.services" :value="item.id">
                                        {{ item.name }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>

                    <div class="columns no-margin">
                        <div class="column is-half">
                            <b-field label="Active"
                                     :type="getErrorClass('is_active')"
                                     :message="getText('is_active')">
                                <b-select
                                    placeholder="Active"
                                    v-model="form.is_active"
                                    expanded>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>

                    <div class="field is-grouped is-grouped-right">
                        <div class="control">
                            <button class="button is-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="columns is-centered " style="margin-top: 10px">
            <div style="margin-top: 10px" class="column is-two-thirds container-box">
                <div class="columns" style="margin-bottom: 0px;">
                    <div class="column">
                        <div class="columns header">
                            <div data-v-3ad9bea0="" class="column"><p data-v-3ad9bea0="" class="day-title">Affiliate
                                Setup</p></div>
                        </div>
                    </div>
                </div>
                <section>
                    <div class="columns" style="margin-top: 5px">
                        <div class="column is-one-third">
                            <inertia-link class="button is-primary"
                                          :href="route('setup.create', {campaign: this.campaignData.campaign.id})">
                                Create
                            </inertia-link>
                        </div>
                    </div>
                    <b-table
                        :data="trackingTable.data"
                        :loading="trackingTable.loading"
                        paginated
                        detailed
                        backend-pagination
                        :has-detailed-visible="(row, index) => row.custom && row.custom.length > 2"
                        :total="trackingTable.total"
                        :per-page="trackingTable.perPage"
                        @page-change="onPageChangeTracking"
                        aria-next-label="Next page"
                        aria-previous-label="Previous page"
                        aria-page-label="Page"
                        aria-current-label="Current page"
                        backend-sorting
                        :default-sort-direction="trackingTable.defaultSortOrder"
                        :default-sort="[trackingTable.sortField, trackingTable.sortOrder]"
                        @sort="onSortTracking"

                    >

                        <template slot-scope="props">

                            <b-table-column field="id" label="ID" sortable>
                                {{ props.row.id }}
                            </b-table-column>

                            <b-table-column field="name" label="Setup name">
                                {{ props.row.name }}
                            </b-table-column>


                            <b-table-column field="affiliate_name" label="Affiliate name">
                                {{ props.row.affiliate_name }}
                            </b-table-column>

                            <b-table-column field="tracking_code" label="Code" sortable>
                                {{ props.row.tracking_code }}
                            </b-table-column>

                            <b-table-column field="accuracy" label="Accuracy">
                                {{ props.row.accuracy }}
                            </b-table-column>

                            <b-table-column field="payout" label="Payout">
                                {{ props.row.payout + ' ' + props.row.currency }}
                            </b-table-column>


                            <b-table-column field="" label="" centered>
                                <b-dropdown aria-role="list">
                                    <i class="fas fa-ellipsis-v table-dropdown-trigger" slot="trigger"
                                       style="cursor: pointer"></i>
                                    <b-dropdown-item aria-role="listitem"><span
                                        v-on:click="deleteTracking(props.row.id)" type="submit">Delete</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-table-column>
                        </template>
                    </b-table>
                </section>
            </div>
        </div>

    </div>
</template>

<script>
import MainMenu from "../../Shared/MainMenu";

export default {
    name: "CreateCampaign",
    components: {
        MainMenu,
    },
    props: {campaignData: Object},
    data() {
        return {
            form: {
                name: this.campaignData.campaign.name,
                start_date: this.campaignData.campaign.start_date,
                subscription_service_id: this.campaignData.campaign.subscription_service_id,
                type: this.campaignData.campaign.type_id,
                is_active: this.campaignData.campaign.is_active,
            },
            trackingTable: {
                data: [],
                total: 0,
                loading: false,
                sortField: 'id',
                sortBy: 'id',
                sortOrder: 'desc',
                defaultSortOrder: 'desc',
                page: 1,
                perPage: 5,
            },
            payoutTable: {
                data: [],
                total: 0,
                loading: false,
                sortField: 'id',
                sortBy: 'id',
                sortOrder: 'desc',
                defaultSortOrder: 'desc',
                page: 1,
                perPage: 5,
            },
        }
    },
    methods: {
        getEditTrackingUrl(id) {
            return this.route('setup.edit', {tracking: id})
        },
        onSortTracking(field, order) {
            this.trackingTable.sortField = field,
                this.trackingTable.sortOrder = order,
                this.loadTrackingData()
        },
        onPageChangeTracking(page) {
            this.trackingTable.page = page
            this.loadTrackingData()
        },
        deleteTracking(id) {
            this.$inertia.delete(this.route('setup.destroy', {tracking: id}));
        },
        loadTrackingData() {
            const params = [
                `sort_by=${this.trackingTable.sortBy}`,
                `sort_order=${this.trackingTable.sortOrder}`,
                `page=${this.trackingTable.page}`,
            ]

            let paramsParsed = params.join('&')

            this.trackingTable.loading = true;
            axios.get(`/setup/list/${this.campaignData.campaign.id}?${paramsParsed}`)
                .then(({data}) => {
                    this.trackingTable.data = [];
                    let currentTotal = data.total;
                    if (data.total / this.trackingTable.perPage > 1000) {
                        currentTotal = this.trackingTable.perPage * 1000;
                    }
                    this.trackingTable.total = currentTotal;
                    data.data.forEach((item) => {
                        this.trackingTable.data.push(item);
                    });


                    this.trackingTable.loading = false;
                })
                .catch((error) => {
                    this.trackingTable.data = [];
                    this.trackingTable.total = 0;
                    this.trackingTable.loading = false;
                    throw error;
                });
        },
        getErrorClass(attribute) {
            if (this.$page.errors[attribute]) {

                return 'is-danger';
            }

            return '';
        },
        getText(attribute) {
            if (this.$page.errors[attribute]) {
                let text = '';
                for (let i = 0; i < this.$page.errors[attribute].length; i++) {
                    text = text + this.$page.errors[attribute][i] + ' ';
                }
                return text;

            }

            return '';
        },
        submit() {
            this.$inertia.put(this.route('campaigns.update', {campaign: this.campaignData.campaign.id}), this.form);
        },
    },
    mounted() {
        this.loadTrackingData();
    }

}
</script>

<style scoped>

</style>
