<style scoped>

.is-active > a {
    color: #4596AC !important;
}
</style>

<template>
    <span>

        <b-navbar name="navbar" wrapper-class="container">
            <template slot="brand">
                <b-navbar-item>
                                        <inertia-link href="/"><img :src="getLogoPath()" alt="OJO"></inertia-link>


                </b-navbar-item>
            </template>
            <template slot="start">

                <b-navbar-item :active="isUrl('/campaigns')">
                    <inertia-link href="/campaigns">
                        Campaigns
                    </inertia-link>
                </b-navbar-item>

                <b-navbar-item :active="isUrl('/affiliates')">
                    <inertia-link href="/affiliates">
                        Affiliates
                    </inertia-link>
                </b-navbar-item>

                <b-navbar-item :active="isUrl('/landing')">
                    <inertia-link href="/landing">
                        Landing Page
                    </inertia-link>
                </b-navbar-item>

                <b-navbar-item :active="isUrl('/subscriptions')">
                    <inertia-link href="/subscriptions">
                         Attempts
                    </inertia-link>
                </b-navbar-item>
                <b-navbar-item :active="isUrl('/logs')">
                    <inertia-link href="/logs">
                         Logs
                    </inertia-link>
                </b-navbar-item>
            </template>
            <template slot="end">


                <b-navbar-item tag="div">
                  <b-dropdown aria-role="list" position="is-bottom-left" class="dropdown-ojo-bar">

                    <span class="circle-username" role="button" slot="trigger"
                          style="color: #4596AC"> {{ this.$page.auth.user.shownName }}</span>

                    <inertia-link href="/profile">
                        <b-dropdown-item>
                            Profile
                        </b-dropdown-item>
                    </inertia-link>

                      <inertia-link href="/users">
                            <b-dropdown-item>
                                    Users
                            </b-dropdown-item>
                        </inertia-link>

                    <inertia-link href="/profile/update/password">
                        <b-dropdown-item>
                            Password
                        </b-dropdown-item>
                    </inertia-link>

                      <inertia-link href="/tokens">
                        <b-dropdown-item>
                            Token
                        </b-dropdown-item>
                    </inertia-link>

                    <hr class="navbar-divider">
                  <inertia-link method="POST" href="/logout">
                        <b-dropdown-item>
                            Logout
                        </b-dropdown-item>
                    </inertia-link>



                </b-dropdown>
                </b-navbar-item>
            </template>
        </b-navbar>
    </span>
</template>
<style>

.circle-username {
    display: block;
    height: 36px;
    width: 36px;
    line-height: 36px;
    -moz-border-radius: 36px;
    border-radius: 36px;
    background-color: #F2F2F2;
    text-align: center;
}

.circle-username:hover {
    cursor: pointer;
}

.navbar {
    height: 70px;
}

.navbar-start a {
    color: #586864 !important;
}
</style>
<script>
export default {

    methods: {
        getLogoPath() {
            return this.$page.app.imagesAssets + '/ignitia.png'
        },
        isUrl(url) {
            if (url === '/') {
                return window.location.pathname === url;
            }

            return window.location.pathname.startsWith(url);
        },
    },
    watch: {},
}
</script>
