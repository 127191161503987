<style scoped>

    .no-margin {
        margin: 0;
    }
</style>
<template>
    <div>
        <main-menu>
        </main-menu>

        <div class="columns is-centered">
            <div class="column is-two-thirds">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <inertia-link href="/affiliates">Affiliates</inertia-link>
                        </li>
                        <li>
                            <inertia-link class="active-breadcrumb" href="/affiliates/create">Create</inertia-link>
                        </li>
                    </ul>
                </nav>
            </div>

        </div>
        <div class="columns is-centered " style="margin-top: 10px">
            <div style="margin-top: 10px" class="column is-two-thirds container-box">

                <div class="columns" style="margin-bottom: 0px;">
                    <div class="column">
                        <div class="columns header">
                            <div data-v-3ad9bea0="" class="column"><p data-v-3ad9bea0="" class="day-title">Create Affiliate</p></div>
                        </div>
                    </div>
                </div>


                <span style="color: #3CD160" v-if="this.$page.flash.success">
                    {{this.$page.flash.success}}
                </span>


                <form @submit.prevent="submit">

                    <div class="columns no-margin">
                        <div class="column">

                            <b-field label="Legal Name"
                                     :type="getErrorClass('legalName')"
                                     :message="getText('legalName')">
                                <b-input type="text"
                                         maxlength="100"
                                         v-model="form.legalName">
                                </b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Affiliate Name"
                                     :type="getErrorClass('affiliateName')"
                                     :message="getText('affiliateName')">
                                <b-input type="text"
                                         maxlength="100"
                                         v-model="form.affiliateName">
                                </b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns no-margin">
                        <div class="column">
                            <b-field label="Country"
                                     :type="getErrorClass('country')"
                                     :message="getText('country')">
                                <b-input type="text"
                                         maxlength="100"
                                         v-model="form.country">
                                </b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Address"
                                     :type="getErrorClass('address')"
                                     :message="getText('address')">
                                <b-input type="text"
                                         maxlength="100"
                                         v-model="form.address">
                                </b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns no-margin">
                        <div class="column is-half">
                            <b-field label="Contact Email"
                                     :type="getErrorClass('contactEmail')"
                                     :message="getText('contactEmail')">
                                <b-input type="text"
                                         maxlength="100"
                                         v-model="form.contactEmail">
                                </b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Accuracy"
                                     :type="getErrorClass('accuracy')"
                                     :message="getText('accuracy')">
                                <b-input type="numeric"
                                         v-model="form.accuracy">
                                </b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns no-margin">
                        <div class="column is-half">
                            <b-field label="Tax ID"
                                     :type="getErrorClass('taxID')"
                                     :message="getText('taxID')">
                                <b-input type="text"
                                         maxlength="100"
                                         v-model="form.taxID">
                                </b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="field is-grouped is-grouped-right">
                        <div class="control">
                            <button class="button is-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import MainMenu from "../../Shared/MainMenu";

    export default {
        name: "CreateAffiliate",
        components: {
            MainMenu,
        },
        props: {affiliateData: Object},
        data() {
            return {
                form: {
                    legalName: null,
                    affiliateName: null,
                    taxID: null,
                    address: null,
                    contactName: null,
                    contactSkype: null,
                    contactEmail: null,
                    type: 1,
                    accuracy: 100,
                },
            }
        },
        methods: {
            getErrorClass(attribute) {
                if (this.$page.errors[attribute]) {

                    return 'is-danger';
                }

                return '';
            },
            getText(attribute) {
                if (this.$page.errors[attribute]) {
                    let text = '';
                    for (let i = 0; i < this.$page.errors[attribute].length; i++) {
                        text = text + this.$page.errors[attribute][i] + ' ';
                    }
                    return text;

                }

                return '';
            },
            submit() {
                this.$inertia.post(this.route('affiliates.store'), this.form);
            },
        }

    }
</script>

<style scoped>

</style>
