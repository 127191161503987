<template>
    <div class="columns is-centered ">

        <div  class="column is-two-thirds container-box">
            <div class="header-box"> Users </div>
            <div class="card-content">

                <a class="button is-primary">
                    <inertia-link :href="getCreateUrl()" style="color:#fff;">Create</inertia-link>
                </a>
                <b-table
                    :data="data"
                    :loading="loading"
                    paginated
                    detailed
                    backend-pagination
                    :has-detailed-visible="(row, index) => row.custom && row.custom.length > 2"
                    :total="total"
                    :per-page="perPage"
                    @page-change="onPageChange"
                    aria-next-label="Next page"
                    aria-previous-label="Previous page"
                    aria-page-label="Page"
                    aria-current-label="Current page"
                    backend-sorting
                    :default-sort-direction="defaultSortOrder"
                    :default-sort="[sortField, sortOrder]"
                    @sort="onSort">

                    <template slot-scope="props">

                        <b-table-column field="id" label="No" sortable>
                            {{ props.row.id }}
                        </b-table-column>

                        <b-table-column field="name" label="N" sortable>
                            {{ props.row.name }}

                        </b-table-column>

                        <b-table-column>
                            <button class="button is-small is-warning">
                                <inertia-link style="color: white" :href="getUpdateUrl(props.row.id)">
                                    Update
                                </inertia-link>
                            </button>

                            <button class="button is-small is-danger">
                                <inertia-link style="color: white" :href="getDeleteUrl(props.row.id)"
                                              method="delete">Delete
                                </inertia-link>
                            </button>

                        </b-table-column>
                    </template>
                </b-table>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        data() {

            return {
                searchModel: '',
                searchQuery: '',
                data: [],
                total: 0,
                loading: false,
                sortField: 'id',
                sortBy: 'id',
                sortOrder: 'desc',
                defaultSortOrder: 'desc',
                page: 1,
                perPage: 5
            }
        },
        methods: {
            getCreateUrl() {
                return '/users/create/';
            },
            getDeleteUrl(id) {
                return '/users/' + id;
            },
            getUpdateUrl(id) {
                return this.route('users.edit', {'user': id});
            },
            loadAsyncData() {
                const params = [
                    `sort_by=${this.sortBy}`,
                    `sort_order=${this.sortOrder}`,
                    `page=${this.page}`,
                    `search_query=${this.searchQuery}`
                ];


                let parsedParams = params.join('&');


                this.loading = true;


                axios.get(`/users/list?${parsedParams}`)
                    .then(({data}) => {
                        this.data = [];
                        let currentTotal = data.total;
                        if (data.total / this.perPage > 1000) {
                            currentTotal = this.perPage * 1000;
                        }
                        this.total = currentTotal;
                        data.data.forEach((item) => {
                            this.data.push(item);
                        });
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.data = [];
                        this.total = 0;
                        this.loading = false;
                        throw error;
                    })
            },
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.sortField = field,
                    this.sortOrder = order,
                    this.loadAsyncData()
            },
        },
        mounted() {
            this.loadAsyncData();
        },
    }
</script>

<style scoped>

</style>
