<template>
    <div>
        <main-menu></main-menu>

        <div class="columns is-centered " style="margin-top: 10px">
            <div style="margin-top: 10px" class="column is-two-thirds container-box">
                <div class="columns" style="margin-bottom: 0px;">
                    <div class="column">
                        <div class="columns header">
                            <div data-v-3ad9bea0="" class="column"><p data-v-3ad9bea0="" class="day-title">Logs</p></div>
                        </div>
                    </div>
                </div>

                <section>
                    <b-table
                        :data="data"
                        :loading="loading"
                        paginated
                        detailed
                        backend-pagination
                        :has-detailed-visible="(row, index) => row.custom && row.custom.length > 2"
                        :total="total"
                        :per-page="perPage"
                        @page-change="onPageChange"
                        aria-next-label="Next page"
                        aria-previous-label="Previous page"
                        aria-page-label="Page"
                        aria-current-label="Current page"
                        backend-sorting
                        :default-sort-direction="defaultSortOrder"
                        :default-sort="[sortField, sortOrder]"
                        @sort="onSort"

                    >

                        <template slot-scope="props" >

                            <b-table-column field="id" label="ID" sortable>
                                {{ props.row.id }}
                            </b-table-column>
                            <b-table-column field="created_at" label="Created at" sortable>
                                {{ props.row.created_at }}
                            </b-table-column>

                            <b-table-column field="context" label="Context" sortable>
                                {{ props.row.context }}
                            </b-table-column>

                            <b-table-column field="message" label="Message" sortable>
                                {{ props.row.message }}
                            </b-table-column>


                            <b-table-column field="level" label="Level" sortable>
                                {{ props.row.level }}
                            </b-table-column>



                        </template>

                    </b-table>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import MainMenu from "../../Shared/MainMenu";
export default {

    data() {
        return {
            searchModel: '',
            searchQuery: '',
            data: [],
            total: 0,
            loading: false,
            sortField: 'id',
            sortBy: 'id',
            sortOrder: 'desc',
            defaultSortOrder: 'desc',
            page: 1,
            perPage: 100
        }
    },
    methods: {

        onSort(field, order) {
            this.sortField = field,
                this.sortOrder = order,
                this.loadAsyncData()
        },
        onPageChange(page) {
            this.page = page
            this.loadAsyncData()
        },
        loadAsyncData() {
            const params = [
                `sort_by=${this.sortBy}`,
                `sort_order=${this.sortOrder}`,
                `page=${this.page}`,
                `search_query=${this.searchQuery}`
            ]

            let paramsParsed = params.join('&')
            this.loading = true;

            axios.get(`/logs/list?${paramsParsed}`)
                .then(({data}) => {
                    console.log(data);
                    this.data = [];
                    let currentTotal = data.total;
                    if (data.total / this.perPage > 100) {
                        currentTotal = this.perPage * 100;
                    }
                    this.total = currentTotal;
                    data.data.forEach((item) => {
                        this.data.push(item);
                    });


                    this.loading = false;
                })
                .catch((error) => {
                    this.data = [];
                    this.total = 0;
                    this.loading = false;
                    throw error;
                })
        }
    },
    components: {
        MainMenu,
    },
    mounted() {
        this.loadAsyncData();
    },
    name: "Index"
}
</script>

<style scoped>

</style>
