<template>
    <section class="hero is-success is-fullheight">
        <div class="hero-body">
            <div class="container ">
                <div class="column is-4 is-offset-4">
                    <div class="box has-text-centered" style="background: rgba(255, 255,255, 0.9555)">
                        <img :src="this.$page.app.imagesAssets +'/ignitia_logo.png'" alt="">

                        <p class="subtitle has-text-grey" >Welcome!</p>


                        <form @submit.prevent="submit"  style="text-align: left">

                            <div class="field" style="padding-bottom: 5px; color: #7A7A97;">
                                <div style="margin-bottom: 2px;color: #7A7A97">Email:</div>

                                <b-field
                                    :type="getEmailType()"
                                    :message="getEmailText()">
                                    <b-input size="is-medium" type="text" placeholder="email@email.com"
                                             icon-pack="fas"
                                             icon="envelope"
                                             v-model="form.email">
                                    </b-input>
                                </b-field>
                            </div>

                            <div class="field" style="padding-bottom: 10px">
                                <div style="margin-bottom: 2px;color: #7A7A97">Password:</div>
                                <b-field
                                         :type="getPasswordType()"
                                         :message="getPasswordText()">
                                    <b-input     size="is-medium" icon-pack="fas"
                                                 icon="lock" type="password"
                                             v-model="form.password">
                                    </b-input>
                                </b-field>
                            </div>

                            <div class="field is-grouped is-grouped-right">
                                <div class="control">
                                    <b-button size="is-medium" v-on:click="submit" type="submit" :loading="this.isLoading"class="button is-primary">Login</b-button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        metaInfo: { title: 'Login' },
        components: {
        },
        props: {
            errors: Object,
        },
        data() {
            return {
                isLoading: false,
                form: {
                    email: null,
                    password: null,
                    remember: null,
                },
            }
        },
        methods: {
            getBackgroundImg() {
                console.log(this.$page.app.imagesAssets);
                return this.$page.app.imagesAssets + '/storm.jpg';
            },
            getEmailType() {
                if (this.$page.errors.email) {
                    return 'is-danger';
                }

                return '';
            },
            getEmailText() {
                if (this.$page.errors.email) {

                    let text = '';
                    for (let i = 0; i < this.$page.errors.email.length; i++) {
                        text = text + this.$page.errors.email[i] + ' ';
                    }
                    return text;

                }
                return '';
            },

            getPasswordType() {
                if (this.$page.errors.password) {

                    return 'is-danger';
                }

                return '';
            },
            getPasswordText() {
                if (this.$page.errors.password) {

                    let text = '';
                    for (let i = 0; i < this.$page.errors.password.length; i++) {
                        text = text + this.$page.errors.password[i] + ' ';
                    }
                    return text;

                }
                return '';
            },
            submit() {
                this.isLoading = true
                let self = this;
                this.$inertia.post(self.route('login.attempt') , {
                    email: this.form.email,
                    password: this.form.password,
                }).then(() => this.isLoading = false).catch(function() {
                    self.$buefy.toast.open({
                        message: 'Wrong credentials',
                        type: 'is-danger',
                        duration: 3000
                    });
                })
            },
        },
    }
</script>

<style>
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    /*Change text in autofill textbox*/
    input:-webkit-autofill {
        -webkit-text-fill-color: #7A7A7A !important;
    }
</style>
<style scoped >

    .hero.is-success {
        background: #F2F6FA;
    }

    .hero .nav, .hero.is-success .nav {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .box {
        border-radius: 8px;
    }


    input {
        font-weight: 300;
    }

    p {
        font-weight: 700;
    }

    p.subtitle {
        font-size: 1.6rem;
    }

    img {
        height: 125px;
    }
</style>
