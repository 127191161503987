<template>

    <div>
        <main-menu>

        </main-menu>

        <div class="columns is-centered">
            <div class="column is-two-thirds">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <inertia-link href="/affiliates">Affiliates</inertia-link>
                        </li>
                        <li>
                            <inertia-link class="active-breadcrumb"
                                          :href="this.route('affiliates.edit', {affiliate: affiliateData.affiliate.id})">
                                Edit
                            </inertia-link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div class="columns is-centered " style="margin-top: 10px">
            <div style="margin-top: 10px" class="column is-two-thirds container-box">
                <div class="columns" style="margin-bottom: 0px;">
                    <div class="column">
                        <div class="columns header">
                            <div class="column"><p class="day-title">Update Affiliate</p></div>
                        </div>
                    </div>
                </div>
                <span style="color: #3CD160" v-if="this.$page.flash.success">
                    {{ this.$page.flash.success }}
                </span>


                <form @submit.prevent="submit">
                    <div class="columns no-margin" style="margin-top: 5px">
                        <div class="column">

                            <b-field label="Legal Name"
                                     :type="getErrorClass('legalName')"
                                     :message="getText('legalName')">
                                <b-input type="text"
                                         maxlength="100"
                                         v-model="form.legalName">
                                </b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Affiliate Name"
                                     :type="getErrorClass('affiliateName')"
                                     :message="getText('affiliateName')">
                                <b-input type="text"
                                         maxlength="100"
                                         v-model="form.affiliateName">
                                </b-input>
                            </b-field>
                        </div>

                    </div>

                    <div class="columns no-margin">
                        <div class="column">
                            <b-field label="Country"
                                     :type="getErrorClass('country')"
                                     :message="getText('country')">
                                <b-input type="text"
                                         maxlength="100"
                                         v-model="form.country">
                                </b-input>
                            </b-field>
                        </div>

                        <div class="column">
                            <b-field label="Address"
                                     :type="getErrorClass('address')"
                                     :message="getText('address')">
                                <b-input type="text"
                                         maxlength="100"
                                         v-model="form.address">
                                </b-input>
                            </b-field>
                        </div>

                    </div>


                    <div class="columns no-margin">

                        <div class="column is-half">
                            <b-field label="Contact Email"
                                     :type="getErrorClass('contactEmail')"
                                     :message="getText('contactEmail')">
                                <b-input type="text"
                                         maxlength="100"
                                         v-model="form.contactEmail">
                                </b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Tax ID"
                                     :type="getErrorClass('taxID')"
                                     :message="getText('taxID')">
                                <b-input type="text"
                                         maxlength="100"
                                         v-model="form.taxID">
                                </b-input>
                            </b-field>
                        </div>
                    </div>




                    <div class="field is-grouped is-grouped-right">
                        <div class="control">
                            <button class="button is-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="columns is-centered " style="margin-top: 10px">
            <div style="margin-top: 10px" class="column is-two-thirds container-box">
                <div class="columns" style="margin-bottom: 0px;">
                    <div class="column">
                        <div class="columns header">
                            <div data-v-3ad9bea0="" class="column"><p data-v-3ad9bea0="" class="day-title">Pixels</p></div>
                        </div>
                    </div>
                </div>

                <section>
                    <div class="columns" style="margin-top: 5px">
                        <div class="column is-one-third">
                            <inertia-link class="button is-primary"
                                          :href="route('pixel.create', {affiliate: this.affiliateData.affiliate.id})">
                                Create
                            </inertia-link>
                        </div>
                    </div>
                    <b-table
                        :data="data"
                        :loading="loading"
                        paginated
                        detailed
                        backend-pagination
                        :has-detailed-visible="(row, index) => row.custom && row.custom.length > 2"
                        :total="total"
                        :per-page="perPage"
                        @page-change="onPageChange"
                        aria-next-label="Next page"
                        aria-previous-label="Previous page"
                        aria-page-label="Page"
                        aria-current-label="Current page"
                        backend-sorting
                        :default-sort-direction="defaultSortOrder"
                        :default-sort="[sortField, sortOrder]"
                        @sort="onSort"

                    >

                        <template slot-scope="props">

                            <b-table-column field="id" label="ID" sortable>
                                {{ props.row.id }}
                            </b-table-column>

                            <b-table-column field="url" label="URL">
                                {{ props.row.url }}
                            </b-table-column>

                            <b-table-column field="" label="" centered>
                                <b-dropdown aria-role="list">
                                    <i class="fas fa-ellipsis-v table-dropdown-trigger" slot="trigger"
                                       style="cursor: pointer"></i>
                                    <inertia-link :href="getEditUrl(props.row.id)">
                                        <b-dropdown-item aria-role="listitem"><span class="no-style">Edit</span>
                                        </b-dropdown-item>
                                    </inertia-link>
                                    <b-dropdown-item aria-role="listitem"><span v-on:click="deletePixel(props.row.id)"
                                                                                type="submit">Delete</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-table-column>
                        </template>

                    </b-table>
                </section>
            </div>



        </div>
        <div class="columns is-centered " style="margin-top: 10px">
            <div style="margin-top: 10px" class="column is-two-thirds container-box">
                <div class="columns" style="margin-bottom: 0px;">
                    <div class="column">
                        <div class="columns header">
                            <div data-v-3ad9bea0="" class="column"><p data-v-3ad9bea0="" class="day-title">Payouts</p></div>
                        </div>
                    </div>
                </div>
                <section>
                    <div class="columns" style="margin-top: 5px">

                        <div class="column is-one-third">
                            <inertia-link class="button is-primary" :href="route('payout.create', {affiliate: this.affiliateData.affiliate.id})">Create</inertia-link>
                        </div>
                    </div>
                    <b-table
                        :data="payoutTable.data"
                        :loading="payoutTable.loading"
                        paginated
                        detailed
                        backend-pagination
                        :has-detailed-visible="(row, index) => row.custom && row.custom.length > 2"
                        :total="payoutTable.total"
                        :per-page="payoutTable.perPage"
                        @page-change="onPageChangePayout"
                        aria-next-label="Next page"
                        aria-previous-label="Previous page"
                        aria-page-label="Page"
                        aria-current-label="Current page"
                        backend-sorting
                        :default-sort-direction="payoutTable.defaultSortOrder"
                        :default-sort="[payoutTable.sortField, payoutTable.sortOrder]"
                        @sort="onSortPayout"

                    >

                        <template slot-scope="props" >
                            <b-table-column field="id" label="ID" sortable>
                                {{ props.row.id }}
                            </b-table-column>

                            <b-table-column field="payout_date"  label="Payout Date">
                                {{ props.row.payout_date }}
                            </b-table-column>

                            <b-table-column field="affiliate_id"  label="Affiliate">
                                {{ props.row.affiliate_id }}
                            </b-table-column>

                            <b-table-column field="payout"  label="Amount">
                                {{ props.row.payout }}
                            </b-table-column>

                            <b-table-column field="currency"  label="Currency">
                                {{ props.row.currency }}
                            </b-table-column>

                            <b-table-column field="" label="" centered>
                                <b-dropdown aria-role="list">
                                    <i class="fas fa-ellipsis-v table-dropdown-trigger" slot="trigger" style="cursor: pointer"></i>
                                    <inertia-link :href="getEditPayoutUrl(props.row.id)">  <b-dropdown-item aria-role="listitem"><span class="no-style">Edit</span></b-dropdown-item></inertia-link>
                                    <b-dropdown-item aria-role="listitem"><span v-on:click="deletePayout(props.row.id)" type="submit">Delete</span></b-dropdown-item>
                                </b-dropdown>
                            </b-table-column>
                        </template>

                    </b-table>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import MainMenu from "../../Shared/MainMenu";

export default {
    components: {
        MainMenu
    },
    props: {
        affiliateData: Object,
    },
    data() {
        return {
            form: {
                legalName: this.affiliateData.affiliate.legal_name,
                affiliateName: this.affiliateData.affiliate.affiliate_name,
                taxID: this.affiliateData.affiliate.tax_id,
                address: this.affiliateData.affiliate.address,
                contactName: this.affiliateData.affiliate.contact_name,
                contactSkype: this.affiliateData.affiliate.contact_skype,
                contactEmail: this.affiliateData.affiliate.contact_email,
                type: this.affiliateData.affiliate.type,
            },
            payoutTable: {
                data: [],
                total: 0,
                loading: false,
                sortField: 'id',
                sortBy: 'id',
                sortOrder: 'desc',
                defaultSortOrder: 'desc',
                page: 1,
                perPage: 5,
            },
            data: [],
            total: 0,
            loading: false,
            sortField: 'id',
            sortBy: 'id',
            sortOrder: 'desc',
            defaultSortOrder: 'desc',
            page: 1,
            perPage: 5
        }
    },
    methods: {
        getEditUrl(id) {
            return this.route('pixel.edit', {pixel: id})
        },
        onSort(field, order) {
            this.sortField = field,
                this.sortOrder = order,
                this.loadAsyncData()
        },
        onPageChange(page) {
            this.page = page
            this.loadAsyncData()
        },
        deletePixel(id) {
            this.$inertia.delete(this.route('pixel.destroy', {pixel: id}));
        },
        getEditPayoutUrl(id) {
            return this.route('payout.edit', {payout: id})
        },
        onSortPayout(field, order) {
            this.payoutTable.sortField = field,
                this.payoutTable.sortOrder = order,
                this.loadPayoutTable();
        },
        onPageChangePayout(page) {
            this.payoutTable.page = page;
            this.loadPayoutTable();
        },
        deletePayout(id) {
            this.$inertia.delete(this.route('payout.destroy',  {payout: id}));
        },
        loadPayoutTable() {
            const params = [
                `sort_by=${this.payoutTable.sortBy}`,
                `sort_order=${this.payoutTable.sortOrder}`,
                `page=${this.payoutTable.page}`,
            ]

            let paramsParsed = params.join('&')

            this.payoutTable.loading = true;
            axios.get(`/payout/list/${this.affiliateData.affiliate.id}?${paramsParsed}`)
                .then(({data}) => {
                    this.payoutTable.data = [];
                    let currentTotal = data.total;
                    if (data.total / this.payoutTable.perPage > 1000) {
                        currentTotal = this.payoutTable.perPage * 1000;
                    }
                    this.payoutTable.total = currentTotal;
                    data.data.forEach((item) => {
                        this.payoutTable.data.push(item);
                    });

                    this.payoutTable.loading = false;
                })
                .catch((error) => {
                    this.payoutTable.data = [];
                    this.payoutTable.total = 0;
                    this.payoutTable.loading = false;
                    throw error;
                });
        },
        loadAsyncData() {
            const params = [
                `sort_by=${this.sortBy}`,
                `sort_order=${this.sortOrder}`,
                `page=${this.page}`,
            ]

            let paramsParsed = params.join('&')

            this.loading = true;
            axios.get(`/pixel/list/${this.affiliateData.affiliate.id}?${paramsParsed}`)
                .then(({data}) => {
                    console.log(data);
                    this.data = [];
                    let currentTotal = data.total;
                    if (data.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000;
                    }
                    this.total = currentTotal;
                    data.data.forEach((item) => {
                        this.data.push(item);
                    });


                    this.loading = false;
                })
                .catch((error) => {
                    this.data = [];
                    this.total = 0;
                    this.loading = false;
                    throw error;
                })
        },
        getErrorClass(attribute) {
            if (this.$page.errors[attribute]) {

                return 'is-danger';
            }

            return '';
        },
        getText(attribute) {
            if (this.$page.errors[attribute]) {
                let text = '';
                for (let i = 0; i < this.$page.errors[attribute].length; i++) {
                    text = text + this.$page.errors[attribute][i] + ' ';
                }
                return text;

            }

            return '';
        },

        submit() {
            this.$inertia.put(this.route('affiliates.update', {affiliate: this.affiliateData.affiliate.id}), this.form);
        },
    },
    mounted() {
        this.loadPayoutTable()
        this.loadAsyncData();
    },
    name: "Edit"
}
</script>

<style scoped>

</style>
