<style scoped>

    .no-margin {
        margin: 0;
    }
</style>
<template>
    <div>
        <main-menu>
        </main-menu>

        <div class="columns is-centered">
            <div class="column is-two-thirds">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <inertia-link href="/landings">Landing Pages</inertia-link>
                        </li>
                        <li>
                            <inertia-link class="active-breadcrumb" href="/landings/create">Create</inertia-link>
                        </li>
                    </ul>
                </nav>
            </div>

        </div>
        <div class="columns is-centered " style="margin-top: 10px">
            <div style="margin-top: 10px" class="column is-two-thirds container-box">

                <div class="columns" style="margin-bottom: 0px;">
                    <div class="column">
                        <div class="columns header">
                            <div class="column"><p class="day-title">Create Landing Page </p></div>
                        </div>
                    </div>
                </div>

                <span style="color: #3CD160" v-if="this.$page.flash.success">
                    {{this.$page.flash.success}}
                </span>


                <form @submit.prevent="submit">

                    <div class="columns no-margin" style="margin-top: 5px">
                        <div class="column">
                            <b-field
                                label="Campaign"
                                :type="getErrorClass('campaign_id')"
                                :message="getText('campaign_id')">
                                <b-select expanded v-model="campaign_id">
                                    <option :key="item.id" v-for="item in campaigns" :value="item.id">{{item.name }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field
                                label="Affiliate Setup"
                                :type="getErrorClass('tracking_id')"
                                :message="getText('tracking_id')">
                                <b-select expanded v-model="tracking_id">
                                    <option :key="item.id" v-for="item in trackings" :value="item.id">
                                        {{item.name }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns no-margin">
                        <div class="column is-half">
                            <b-field
                                label="Name"
                                :type="getErrorClass('name')"
                                :message="getText('name')">
                                <b-input expanded v-model="name">
                                </b-input>
                            </b-field>
                        </div>
                    </div>

                    <div class="field is-grouped is-grouped-right">
                        <div class="control">
                            <button class="button is-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import MainMenu from "../../Shared/MainMenu";

    export default {
        name: "CreateAffiliate",
        components: {
            MainMenu,
        },

        data() {
            return {
                campaigns: [],
                trackings: [],
                payouts: [],
                tracking_id: null,
                campaign_id: null,
                payout_id: null,
                name: null,
            }
        },
        watch: {
            campaign_id: function (val) {
                let that = this;

                axios.get('/trackings/filter', {params: {campaign_id: val}}).then(function (response) {
                    that.trackings = response.data;
                });

                axios.get('/payouts/filter', {params: {campaign_id: val}}).then(function (response) {
                    that.payouts = response.data;
                });
            }
        },
        methods: {
            getErrorClass(attribute) {
                if (this.$page.errors[attribute]) {

                    return 'is-danger';
                }

                return '';
            },
            getText(attribute) {
                if (this.$page.errors[attribute]) {
                    let text = '';
                    for (let i = 0; i < this.$page.errors[attribute].length; i++) {
                        text = text + this.$page.errors[attribute][i] + ' ';
                    }
                    return text;

                }

                return '';
            },
            submit() {
                this.$inertia.post(this.route('landings.store'), {
                    campaign_id: this.campaign_id,
                    tracking_id: this.tracking_id,
                    name: this.name,
                    payout_id: this.payout_id
                });
            },
        },
        mounted() {
            let that = this;

            axios.get('/campaigns/all').then(function (response) {
                that.campaigns = response.data;
            });
        }
    }
</script>

<style scoped>

</style>
