<template>

    <section class="hero  is-fullheight">
        <div class="hero-body">
            <div class="container ">
                <div class="column is-4 is-offset-4">
                    <div class="box ">
                        <b-field>
                            <b-input placeholder="Type your phone number"
                                     type="text"
                                     icon-pack="fas"
                                     icon="phone">
                            </b-input>    <span style="margin-left: 10px"><b-button type="is-primary">Subscribe</b-button></span>
                        </b-field>

                    </div>
                </div>
            </div>
        </div>
    </section>


</template>

<script>
    export default {
        props: {
            landingPageData: Object,
        },
        name: "Show",
        mounted() {
            console.log(this.landingPageData.trackingCode);
        }
    }
</script>

<style scoped>

</style>
