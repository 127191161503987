<template>
    <div>
        <main-menu></main-menu>

        <div class="columns is-centered " style="margin-top: 10px">
            <div style="margin-top: 10px" class="column is-two-thirds container-box">
                <div class="columns" style="margin-bottom: 0px;">
                    <div class="column">
                        <div class="columns header">
                            <div data-v-3ad9bea0="" class="column"><p data-v-3ad9bea0="" class="day-title">Api Token</p>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-narrow">
                                <b-button type="is-primary" v-on:click="createToken()">Generate</b-button>
                            </div>
                            <div class="column">
                                <b-field>
                                    <b-input readonly v-model="token"></b-input>
                                </b-field>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">

                            </div>
                        </div>
                    </div>
                </div>

                <section></section>
            </div>
        </div>
    </div>
</template>

<script>
import MainMenu from "../../Shared/MainMenu";

export default {
    props: {tokenData: Object},
    data() {
        return {
            token: '',
        }
    },
    methods: {
        createToken() {
            axios.post('/tokens').then((response) => {
                this.token = response.data.token;
            }).catch(() => {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: `Something's not good`,
                    type: 'is-danger'
                });
            });
        }
    },
    mounted() {
      console.log(this.tokenData.token);
    },
    components: {
        MainMenu,
    },
    name: "Index"
}
</script>

<style scoped>

</style>
