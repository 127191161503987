<template>
    <div>
        <main-menu></main-menu>

        <div class="column">
            <div class="columns is-centered is-vcentered">
                <div style="margin-top: 10px" class="column is-two-thirds">
                    <div class="container-box">
                        <div class="card-content">
                            <div style="color: green" v-if="$page.flash.success"> {{$page.flash.success}}</div>
                            <form method="POST" @submit.prevent="submit()">
                                <div class="field">
                                    <b-field
                                        :label=" $page.app.translations.name"
                                        :type="getType('name')"
                                        :message="getText('name')">
                                        <b-input type="text" v-model="form.name">
                                        </b-input>
                                    </b-field>
                                </div>

                                <div class="field">
                                    <b-field
                                        :label="$page.app.translations.email"
                                        :type="getType('email')"
                                        :message="getText('email')">
                                        <b-input type="text" placeholder="email@email.com"
                                                 v-model="form.email">
                                        </b-input>
                                    </b-field>
                                </div>
                                <div class="field is-grouped is-grouped-right">
                                    <div class="control">
                                        <b-button @click="submit" :loading="isLoading" class="button is-primary">
                                            {{ $page.app.translations.update }}
                                        </b-button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import MainMenu from "../../Shared/MainMenu";

    export default {
        components: {
            MainMenu,
        },
        data() {
            return {
                isLoading: false,

                form: {
                    name: null,
                    email: null,
                    language: null,
                }
            }
        },
        props: {
            profileData: Object,
        },
        methods: {
            getType(property) {
                if (this.$page.errors[property]) {

                    return 'is-danger';
                }

                return '';
            },
            getText(property) {
                if (this.$page.errors[property]) {

                    let text = '';
                    for (let i = 0; i < this.$page.errors[property].length; i++) {
                        text = text + this.$page.errors[property][i] + ' ';
                    }
                    return text;

                }
                return '';
            },
            submit() {
                let that = this;

                console.log(this.form.language);
                that.isLoading = true;
                this.$inertia.post('/profile/update', this.form)
                    .then(function () {
                        that.isLoading = false;
                    });
            }
        },
        mounted() {
            this.form.email = this.profileData.user.email;
            this.form.name = this.profileData.user.name;
            this.form.language = this.profileData.user.language;

        }
    }
</script>

<style scoped>

</style>
