<template>
    <div>

        <main-menu>
        </main-menu>

        <div class="columns is-centered">
            <div class="column is-two-thirds">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <inertia-link :href="this.route('affiliates.edit', {affiliate: this.pixelData.pixel.affiliate_id})">Pixels</inertia-link>
                        </li>
                        <li>
                            <inertia-link class="active-breadcrumb" :href="this.route('pixel.edit', {pixel: this.pixelData.pixel.id})">Edit Pixel</inertia-link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="columns is-centered " style="margin-top: 10px">
            <div style="margin-top: 10px" class="column is-two-thirds container-box">
                <span style="color: #3CD160" v-if="this.$page.flash.success">
                    {{this.$page.flash.success}}
                </span>
                <div class="columns" style="margin-bottom: 0px;">
                    <div class="column">
                        <div class="columns header">
                            <div data-v-3ad9bea0="" class="column"><p data-v-3ad9bea0="" class="day-title">Update Pixel</p></div>
                        </div>
                    </div>
                </div>
                <form @submit.prevent="submit" style="margin-top: 10px">



                    <div class="columns no-margin">
                        <div class="column">
                            <b-field label="URL"
                                     :type="getErrorClass('url')"
                                     :message="getText('url')">
                                <b-input type="text"
                                         maxlength="100"
                                         v-model="form.url">
                                </b-input>
                            </b-field>
                        </div>
                    </div>

                    <div class="field is-grouped is-grouped-right">
                        <div class="control">
                            <button class="button is-primary">Submit</button>
                        </div>
                    </div>

                    <input type="hidden" v-model="this.form.affiliate_id">
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import MainMenu from "../../Shared/MainMenu";

    export default {
        name: "Create",
        props: {pixelData: Object},
        components: {
            MainMenu,
        },
        data() {
            return {
                form: {
                    affiliate_id: this.pixelData.pixel.affiliate_id,
                    argument_1: this.pixelData.pixel.argument_1,
                    argument_2: this.pixelData.pixel.argument_2,
                    url: this.pixelData.pixel.url,
                },
            }
        },
        methods: {
            getErrorClass(attribute) {
                if (this.$page.errors[attribute]) {

                    return 'is-danger';
                }

                return '';
            },
            getText(attribute) {
                if (this.$page.errors[attribute]) {
                    let text = '';
                    for (let i = 0; i < this.$page.errors[attribute].length; i++) {
                        text = text + this.$page.errors[attribute][i] + ' ';
                    }
                    return text;

                }

                return '';
            },
            submit() {
                this.$inertia.put(this.route('pixel.update', {pixel: this.pixelData.pixel.id}), this.form);
            },
        }
    }
</script>

<style scoped>

</style>
