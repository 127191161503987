<style scoped>

.no-margin {
    margin: 0;
}
</style>
<template>
    <div>
        <main-menu>
        </main-menu>

        <div class="columns is-centered">
            <div class="column is-two-thirds">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <inertia-link href="/campaigns">Campaigns</inertia-link>
                        </li>
                        <li>
                            <inertia-link class="active-breadcrumb" href="/campaigns/create">Create</inertia-link>
                        </li>
                    </ul>
                </nav>
            </div>

        </div>
        <div class="columns is-centered " style="margin-top: 10px">
            <div style="margin-top: 10px" class="column is-two-thirds container-box">

                <div class="columns" style="margin-bottom: 0px;">
                    <div class="column">
                        <div class="columns header">
                            <div class="column"><p class="day-title">Create Campaign</p></div>
                        </div>
                    </div>
                </div>

                <span style="color: #3CD160" v-if="this.$page.flash.success">
                    {{ this.$page.flash.success }}
                </span>


                <form @submit.prevent="submit">

                    <div class="columns no-margin" style="margin-top: 5px">
                        <div class="column is-half">

                            <b-field label="Name"
                                     :type="getErrorClass('name')"
                                     :message="getText('name')">
                                <b-input type="text"
                                         maxlength="100"
                                         v-model="form.name">
                                </b-input>
                            </b-field>
                        </div>

                        <div class="column">
                            <b-field label="Type"
                                     :type="getErrorClass('type')"
                                     :message="getText('type')">
                                <b-select
                                    placeholder="Type"
                                    v-model="form.type"
                                    expanded>
                                    <option v-for="item in this.campaign.types" :value="item.id">{{item.name}}</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>


                    <div class="columns no-margin">
                        <div class="column">
                            <b-field label="Start date"
                                     :type="getErrorClass('start_date')"
                                     :message="getText('start_date')">
                                <b-input type="date"
                                         maxlength="100"
                                         v-model="form.start_date">
                                </b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Subscription service"
                                     :type="getErrorClass('subscription_service_id')"
                                     :message="getText('subscription_service_id')">
                                    <b-select
                                        placeholder="Service"
                                        v-model="form.subscription_service_id"
                                        expanded>
                                        <option v-for="item in this.campaign.services" :value="item.id">{{item.name}}</option>
                                    </b-select>
                            </b-field>
                        </div>
                    </div>

                    <div class="columns no-margin">
                        <div class="column is-half">
                            <b-field label="Active"
                                     :type="getErrorClass('subscription_service_id')"
                                     :message="getText('subscription_service_id')">
                                <b-select
                                    placeholder="Active"
                                    v-model="form.is_active"
                                    expanded>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>

                    <div class="field is-grouped is-grouped-right">
                        <div class="control">
                            <button class="button is-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import MainMenu from "../../Shared/MainMenu";

export default {
    props: {
        campaign: Object,
    },
    name: "CreateAffiliate",
    components: {
        MainMenu,
    },
    data() {
        return {
            form: {
                name: null,
                start_date: null,
                subscription_service_id: null,
                type: null,
                is_active: 1,
            },
        }
    },

    methods: {
        getErrorClass(attribute) {
            if (this.$page.errors[attribute]) {

                return 'is-danger';
            }

            return '';
        },
        getText(attribute) {
            if (this.$page.errors[attribute]) {
                let text = '';
                for (let i = 0; i < this.$page.errors[attribute].length; i++) {
                    text = text + this.$page.errors[attribute][i] + ' ';
                }
                return text;

            }

            return '';
        },
        submit() {
            this.$inertia.post(this.route('campaigns.store'), this.form);
        },
    },
    mounted() {
        console.log(this.campaign.services);
    }
}
</script>

<style scoped>

</style>
