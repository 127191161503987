<template>
        <div>

            <main-menu>
            </main-menu>

            <div class="columns is-centered">
                <div class="column is-two-thirds">
                    <nav class="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <inertia-link href="/affiliates">Affiliates</inertia-link>
                            </li>

                            <li>
                                <inertia-link :href="this.route('affiliates.edit', {affiliate: this.affiliateData.affiliate.id})">Pixels</inertia-link>
                            </li>
                            <li>
                                <inertia-link class="active-breadcrumb" :href="this.route('pixel.create', {affiliate: this.affiliateData.affiliate.id})">Create Pixel</inertia-link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div class="columns is-centered " style="margin-top: 10px">
                <div style="margin-top: 10px" class="column is-two-thirds container-box">
                <span style="color: #3CD160" v-if="this.$page.flash.success">
                    {{this.$page.flash.success}}
                </span>

                    <div class="columns" style="margin-bottom: 0px;">
                        <div class="column">
                            <div class="columns header">
                                <div data-v-3ad9bea0="" class="column"><p data-v-3ad9bea0="" class="day-title">Create Pixel</p></div>
                            </div>
                        </div>
                    </div>
                    <form @submit.prevent="submit" style="margin-top: 10px">

                        <div class="columns no-margin">
                            <div class="column">
                                <b-field label="Pixel URL"
                                         :type="getErrorClass('url')"
                                         :message="getText('url')">
                                    <b-input type="text"
                                             maxlength="100"
                                             placeholder="http://url_pixel/pixel_code"
                                             v-model="form.url">
                                    </b-input>
                                </b-field>
                            </div>
                        </div>

                        <div class="field is-grouped is-grouped-right">
                            <div class="control">
                                <button class="button is-primary">Submit</button>
                            </div>
                        </div>

                        <input type="hidden" v-model="this.form.affiliate_id">
                    </form>
                </div>
            </div>
        </div>
</template>

<script>
    import MainMenu from "../../Shared/MainMenu";

    export default {
        name: "Create",
        props: {affiliateData: Object},
        components: {
            MainMenu,
        },
        data() {
            return {
                form: {
                    affiliate_id: this.affiliateData.affiliate.id,
                    argument_1: null,
                    argument_2: null,
                    url: null,
                },
            }
        },
        methods: {
            getErrorClass(attribute) {
                if (this.$page.errors[attribute]) {

                    return 'is-danger';
                }

                return '';
            },
            getText(attribute) {
                if (this.$page.errors[attribute]) {
                    let text = '';
                    for (let i = 0; i < this.$page.errors[attribute].length; i++) {
                        text = text + this.$page.errors[attribute][i] + ' ';
                    }
                    return text;

                }

                return '';
            },
            submit() {
                this.$inertia.post(this.route('pixel.store'), this.form);
            },
        }
    }
</script>

<style scoped>

</style>
